'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import React, { Suspense, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '@/src/lib/client-utils';
import styles from '../../styles/Home.module.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlaygroundHeader } from "../components/playground/PlaygroundHeader";
import { ChevronDown, Code, FileText, LayoutDashboard, Users } from 'lucide-react';

const taskTypes = [
  { value: 'Socratic_Interview', label: 'Socratic Interview', icon: FileText },
];

const tasks = {
  'Socratic_Tutoring': ['Coding', 'System Design', 'Case Study', 'DSA'],
  'Socratic_Interview': ['Coding', 'Behavioural', 'System_Design', 'Case_Study', 'DSA'],
};
function DemoMeetingTab(props: { label: string }) {
  const router = useRouter();
  const [e2ee, setE2ee] = useState(false);
  const [sharedPassphrase, setSharedPassphrase] = useState(randomString(64));
  const [selectedTaskType, setSelectedTaskType] = useState('Socratic_Interview');
  const [selectedTask, setSelectedTask] = useState('Coding');
  const [selectionFlag, setSelectionFlag] = useState('Socratic_Interview-Coding');
  const startMeeting = () => {
    const roomId = generateRoomId();
    let route = `/rooms/${roomId}`;
    if (selectionFlag) {
      route += `?selectionFlag=${selectionFlag}`;
    }
    if (e2ee) {
      router.push(`${route}#${encodePassphrase(sharedPassphrase)}`);
    } else {
      router.push(route);
    }
  };

  const handleTaskTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedTaskType(value);
    setSelectedTask('');
    setSelectionFlag('');
  };

  const handleTaskChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedTask(value);
    setSelectionFlag(`${'Socratic_Interview'}-${value}`);
  };
  return (
    <div className={styles.tabContent}>
      <p style={{ margin: 0 }}>Try Pashyatu Hire for free with our live demo.</p>
      <h2 style={{textAlign:'center'}}>
           Built using LiveKit and Next.js
        </h2>
        <div className="space-y-2">
          <label htmlFor="task" className="block text-sm font-medium text-[#06b6d4]">Task</label>
          <div className="relative">
            <select
              id="task"
              value={selectedTask}
              onChange={handleTaskChange}
              className="w-full bg-gray-700 border border-gray-600 text-gray-100 rounded-md py-2 pl-3 pr-10 appearance-none focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              <option value="">Select a task</option>
              {tasks['Socratic_Interview'].map((task) => (
                <option key={task} value={task}>
                  {task}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
          </div>
        </div>
      <button style={{ marginTop: '1rem' }} className="lk-button" onClick={startMeeting}>
        Start a Demo Meeting
      </button>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
        </div>
      </div>
    </div>
  );
}


export default function Page() {
const config = {
  title: "Pashyatu Hire: Your AI-powered Interview Platform",
  description: "Pashyatu Hire is an AI-powered interview platform that helps automate the interview process.",
  video_fit: "cover",
  settings: {
    editable: true,
    theme_color: "cyan",
    chat: true,
    inputs: {
      camera: true,
      mic: true,
    },
    outputs: {
      audio: true,
      video: true,
    },
    ws_url: "",
    token: "",
  },
  show_qr: false,
}
const headerHeight = 60;

  return (
    <>
      <PlaygroundHeader title={"Pashyatu Hire: Your AI-powered Interview Platform"} height={60} accentColor={"cyan"} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <main className={styles.main} data-lk-theme="default">
      
        <div className="header">
          <div className="flex items-center gap-3 basis-2/3">
          {/* <img src="/vidvatta_illustration.png" alt="Pashyatu Hire" width="80" height="80" style={{display:'inline'}}/> */}
          Pashyatu Hire: Your AI-powered Interview Platform.

          </div>
          
        </div>
        <DemoMeetingTab label="Demo" />
      </main>
      <footer data-lk-theme="default">
        <p>© 2024 Pashyatu AI Technologies Pvt Ltd. All rights reserved</p>
      </footer>
    </>
  );
}
