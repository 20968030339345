import { ReactNode } from "react";

type PlaygroundHeader = {
  title?: ReactNode;
  height: number;
  accentColor: string;
};

export const PlaygroundHeader = ({
  title,
  accentColor,
  height,
}: PlaygroundHeader) => {
  return (
    <div
      className={`flex gap-4 text-${accentColor}-500 justify-between items-center shrink-0 border-b rounded-sm border-gray-800 w-full`}
      style={{
        height: height + "px",
      }}
    >
      <div className="flex items-center gap-3 basis-2/3">
        <div className="flex lg:basis-1/2">
          <a href="https://www.pashyatu.ai">{<img src="/vidvatta_illustration.png" alt="Vidvatta Logo" width="60" height="60" />}</a>
        </div>
        <div className="lg:basis-1/2 lg:text-center text-xs lg:text-base lg:font-semibold">
          {title}
        </div>
      </div>
    </div>
  );
};

